.forgot-container {
	width: min(100%, 450px);
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	box-sizing: border-box;
	padding-top: 90px;
}

.forgot-input-field {
	margin-top: 34px;
	width: 100%;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;

	color: #060606;
}

.password-mismatch-border {
	border: 2px solid red;
}

.styled-button-forgot {
	width: 100%;
	/* height: 72px; */
}

.forgot-container-header {
	margin-top: 30px;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;

	color: #060606;
	align-self: flex-start;
}
