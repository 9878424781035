body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.test-css {
	background-color: blue;
}

.nav-bar {
	background-color: #111111;
	height: 60px;
}

.just-collab-logo {
	height: 200px;
	width: 180px;
	margin-left: -30px;
}

.navbar-menu {
	margin-top: 50px;
}

.navbar-menu > .MuiMenu-paper {
	width: 200px;
	background-color: #111111;
	border-radius: 0px !important;
	padding: 0px !important;
}

.navbar-menu > .MuiMenu-paper > .MuiList-root {
	padding: 0px !important;
}

.logo-menu-item {
	background-color: #111111 !important;
	padding: 0 !important;
}

.logo-menu-route {
  height: 55px;
	width: 100%;
  line-height: 55px;
	text-decoration: none;
	color: white;
	padding-left: 20px;
  border-bottom: 1px solid white;
}
