.signup-container {
	width: min(100%, 450px);
	padding: 0 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding-bottom: 45px;
}

.login-link-text{
	margin-top: 14px;
	align-self: flex-start;
	text-decoration: none;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;

	color: #060606;	
}

.signup-input-field {
	margin-top: 43px;
}

.signup-container-header {
	margin-top: 90px;
	margin-bottom: 37px;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 62px;
	align-self: flex-start;

	color: #060606;
}

.platform-link-holder {
	display: flex;
	gap: 16px;
	margin-top: 43px;
	width: 100%;
}

.platform-link-logo-holder {
	height: 77px;
	width: 77px !important;

	background: #f4f4f4;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.platform-link-logo {
	height: 40px;
	width: 40px;
}

.platform-link-input{
	flex: 1;
}

.signup-link-display-subtext {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
    margin-top: 535px;

	color: #5e5e5e;
    align-self: center;
}
