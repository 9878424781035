.home-container {
	width: 100%;
	height: 100%;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
	/* background-color: blue; */
}

.home-welcome-container {
	height: 300px;
	width: 100%;
	margin: 0 auto;
	background: #111111;
	border-radius: 18px;
	box-sizing: border-box;
	padding: 22px;
	margin-top: -20px;
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: flex-start;
}

.welcome-text {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 42px;
	line-height: 62px;
	color: #bbbbbb;
	color: #ffffff;
	text-align: left;
}

.welcome-text-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	color: #bbbbbb;
}

.proposal-list-container {
	width: min(100%, 450px);
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	box-sizing: border-box;
	margin-top: 84px;
}

.single-proposal-item {
	width: 100%;
	height: 71px;
	border-radius: 17px;
	box-sizing: border-box;
	padding: 25px;
	margin-top: 31px;

	text-align: left;
	text-decoration: none;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 21px;

	color: #777777;
}
