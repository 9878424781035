.App {
	text-align: center;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.test-css {
	background-color: white;
}

.nav-bar {
  width: 100%;
  padding: 20px;
	display: flex;
	justify-content: space-between;
  align-items: center;
  /* background-color: gray; */
}

.nav-bar-volyume {
	font-family: Epilogue;
	font-size: 19px;
	font-weight: 300;
	line-height: 28px;
	letter-spacing: 5px;
	text-align: center;
}

.nav-menu-button{
  justify-self: flex-end;
}

.loading-progress-indicator{
	color: #060606 !important;
	align-self: center;
	margin-top: 20px;
}