.login-container {
	width: min(100%, 450px);
	padding: 0 35px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
}

.login-input-field {
	margin-top: 34px;
}

.password-mismatch-border {
	border: 2px solid red;
}

.styled-button-profile-save {
	width: 100%;
}

.login-container-header {
	margin-top: 90px;
	margin-bottom: 37px;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 62px;
	align-self: flex-start;

	color: #060606;
}

.forgot-password-link {
	margin-top: 28px;
	align-self: flex-end;
	text-decoration: none;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;

	color: #060606;
}
