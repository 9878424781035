.profile-save-container {
	width: min(100%, 450px);
	height: 100vh;
	padding: 0 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding-bottom: 45px;
	justify-content: center;
}

.profile-save-hurray {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 62px;
}

.profile-save-text {
	margin-top: 20px;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;

	color: #060606;
}

.profile-save-button {
	width: 80%;
	font-size: 18px;
}

.saved-profile-link-display-subtext {
	position: absolute;
	bottom: 20px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;

	color: #5e5e5e;
	align-self: center;
}
