.rejected-proposal-user-container {
	width: min(100%, 450px);
	height: 100vh;
	padding: 0 30px;
	margin: 0 auto;
	padding-bottom: 45px;
}

.rejected-proposal-notify {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;

	color: #060606;

	margin-top: 210px;
	text-align: left;
}

.rejected-proposal-link-display-subtext {
	position: absolute;
	bottom: 20px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;

	color: #5e5e5e;
	align-self: center;
}

.rejected-proposal-dashboard-button {
	height: 72px;
	width: 100%;
	background: #f2f3f7;
	border-radius: 17px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-top: 43px;

	text-decoration: none;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;

	color: #060606;
}

.rejected-proposal-back {
	position: absolute;
	width: 15px;
	height: 15px;
	margin-left: 30px;
	left: 40px;
}