.proposal-view-container {
	width: min(100%, 450px);
	margin: 0 auto;
	margin-top: 20px;
	/* height: 100%; */
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
	padding-bottom: 20px;
	/* border: 1px solid blue; */
}

.proposal-brand-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;

	color: #777777;
	margin-bottom: 52px;
}

.proposal-input-field {
	margin-top: 28px;
}

.proposal-text-area-field {
	margin-top: 28px;
	/* width: 100%; */
}

.proposal-label {
	margin-top: 24px;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	text-align: left;
	color: #515151;
}

.styled-anchor-mail {
	width: 100%;
	height: 72px;
	line-height: 72px;
}

.styled-anchor-whatsapp {
	width: 100%;
	height: 72px;
	line-height: 72px;
	background: #128c7e;
}

.styled-anchor-reject {
	width: 100%;
	height: 72px;
	line-height: 72px;
	background: #9E0202;;
}

.proposal-assist-button {
	width: 100%;
	height: 72px;
	background: #f2f3f7;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	border: none;

	color: #777777;
}

.proposal-link-display-subtext {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	margin-top: 175px;

	color: #5e5e5e;
	align-self: center;
}

.proposal-platforms-view {
	display: flex;
	margin-top: 25px;
}

.proposal-platform-selected {
	width: 51px;
	height: 48px;

	background: #f4f4f4;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	margin-right: 36px;
}

.proposal-platform-selected > img {
	width: 18px;
	height: 18px;
}

.proposal-not-interested-label {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;

	color: #060606;
	margin-top: 70px;
}
