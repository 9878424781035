.profile-container {
	width: min(100%, 450px);
	margin: 0 auto;
	height: 100%;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 10px;
}

.profile-header-text {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;

	color: #060606;
}

.interest-container {
	margin-top: 20px;
	margin-bottom: 10px;
	max-width: 90%;
}

.interest-list {
	display: flex;
	flex-wrap: wrap;
}

.tag-unselected {
	background: #f0f0f0 !important;
	color: #333333 !important;
}

.tag-selected {
	background: linear-gradient(114.44deg, #14142b 0%, #292929 100%);
	color: #fcfcfc !important;
}

.profile-scale-slider {
	width: 100%;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 50px;

	color: #060606;
	height: 50px;
	
	display: flex;
	align-items: center;
}

.profile-slider {
	width: 80% !important;
	align-self: center;
	color: black !important;
	margin-left: 10px;
}

.gender-split-container {
	width: 100%;
	display: flex;
}

.gender-split-value-container {
	flex: 1;
	text-align: center;
}

.profile-input-field {
	margin-top: 34px;
}

.profile-input-field::placeholder {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: gray;

	text-align: left;
}

.styled-button-profile-save {
	align-self: center;
	margin-bottom: 50px;
}

.whatsapp-checkbox-container {
	display: flex;
	width: 100%;
	align-items: center;
	margin-top: 27px;
}

.whatsapp-checkbox {
	align-self: flex-start;
	text-align: left;
	font-family: 'DM Sans' !important;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 48px;

	color: #060606;
}

.profile-url-field-container {
	display: flex;
	align-items: center;
	width: 100%;
}

.profile-url-input {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.profile-image-input-holder {
	margin-top: 34px;
	margin-bottom: 34px;
	display: flex;
	width: 100%;
	height: 76px;
	align-items: center;
}

.profile-image-upload-icon {
	width: 36px;
	height: 36px;
	margin-left: 35px;
	margin-right: 18px;
}

.profile-image-input {
	visibility: hidden;
	display: none;
}

.profile-image-label {
	flex: 1;
	background: #f2f3f7;
	border-radius: 18px;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 76px;

	color: #060606;
}

.profile-image-discard{
	background-color: white;
	color: red;
	border-color: red;
	margin: 0 auto;
	width: 150px;
}

.profile-image-action-buttons{
	width: 90%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.profile-image-save{
	margin: 0 auto;
	width: 150px;
}

.avatar-editor {
	margin: 0 auto;
	width: 100%;
}
